// Add project specific javascript code and import of additional bundles here:

import PreviewModeToggler from './views/Form/toolbarActions/previewModeToggler';
import {formToolbarActionRegistry} from 'sulu-admin-bundle/views';

// Implement custom extensions here

formToolbarActionRegistry.add('app_admin.previewModeToggler', PreviewModeToggler);

import {ckeditorPluginRegistry, ckeditorConfigRegistry} from 'sulu-admin-bundle/containers';
import Font from '@ckeditor/ckeditor5-font/src/font';

ckeditorPluginRegistry.add(Font);
ckeditorConfigRegistry.add((config) => ({
    fontBackgroundColor: {
        colors: [
            {
                color: '#f1e740',
                label: 'Mark to change to Secondary Color'
            }
        ]
    },
    toolbar: [...config.toolbar, 'fontBackgroundColor'],
}));